<template>
  <div class="divider">
    <div class="line left"></div>
    <h4 class="title">{{ copy }}</h4>
    <div class="line right"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "divider",
  props: {
    copy: {
      type: String,
      required: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  text-align: center;

  .title {
    margin: 0 12px;
    color: #000000B8;
    font-weight: 400;
  }

  .line {
    position: relative;
    width: 100%;
    height: 1px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000000B8;
      opacity: 0.24;
    }

    // &.left {
    //   &::before {
    //     background-image: linear-gradient(
    //       270deg,
    //       rgba(255, 255, 255, 1) 0%,
    //       rgba(255, 255, 255, 1) 60%,
    //       rgba(255, 255, 255, 0) 100%
    //     );
    //   }
    // }

    // &.right {
    //   &::before {
    //     background-image: linear-gradient(
    //       90deg,
    //       rgba(255, 255, 255, 1) 0%,
    //       rgba(255, 255, 255, 1) 60%,
    //       rgba(255, 255, 255, 0) 100%
    //     );
    //   }
    // }
  }
}
</style>
