<template>
  <div class="page home">
    <div class="background-container"></div>
    <div class="full-width">

      <section class="topbar">
        <img src="@/assets/images/logos/logo.png" class="logo" alt="logo"/>
      </section>

      <h2 class="upper-title">{{ $t('title') }}</h2>


      <section class="video">

        <div class="video-box" id="video-box" :style="`min-height: ${boxHeight}px;`">
          <video id="video" ref="video" class="video" controlsList="nofullscreen" controls :src="video"
                 :poster="thumbnail"></video>

          <img id="format" class="format" :src="format" alt="video format">

        </div>

        <div class="description">
          <p>{{ $t('paragraph1') }}</p>
          <p>{{ $t('paragraph2') }}</p>
          <div>{{ $t('paragraph3') }} <a :href="$t('link.href')">{{ $t('link.copy') }}</a></div>

        </div>

        <div class="divider">
          <div class="line left"></div>
        </div>

        <div class="share">
          <div class="share-title">{{ $t('share') }}</div>
          <div class="desktop-share">
            <div class="button linkedin" @click="handleShare('linkedin')">
              <img src="@/assets/images/icons/icon-linkedin.svg" alt="Share on linkedin" class="icon"/>
              <div>LinkedIn</div>
            </div>
            <div class="button twitter" @click="handleShare('twitter')">
              <img src="@/assets/images/icons/icon-twitter.svg" alt="Share on twitter" class="icon"/>
              <div>Twitter</div>
            </div>
            <div class="button facebook" @click="handleShare('facebook')">
              <img src="@/assets/images/icons/icon-facebook.svg" alt="Share on facebook" class="icon"/>
              <div>Facebook</div>
            </div>
            <div class="button whatsapp" @click="handleShare('whatsapp')">
              <img src="@/assets/images/icons/icon-whatsapp.svg" alt="Share on whatsapp" class="icon"/>
              <div>Whatsapp</div>
            </div>
          </div>
          <div class="mobile-holder">
            <div class="button general" @click="handleShare('general')">
              <!-- <img src="@/assets/images/icons/icon-share.svg" class="icon" /> -->
              <span>{{ $t('share') }}</span>
            </div>
          </div>
          <Divider copy="of"/>
          <div class="download-holder">
            <div class="button download" @click="handleShare('download')">
              <img src="@/assets/images/icons/icon-download.svg" alt="Download video" class="icon"/>
              <span>{{ $t('download') }}</span>
            </div>
          </div>
        </div>
      </section>
      <section class="point-tutorial">
        <img :src="tutorialhref" alt="Points tutorial">
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {LocationQueryValue} from 'vue-router';
import axios from "axios";
import Divider from "@/components/Divider.vue";

export default defineComponent({
  name: "Home",
  components: {Divider},
  data() {
    return {
      isPlaying: false,
      flowTwo: false,
      videoHeight: 400,
      videoWidth: 400,
      boxHeight: 100,
      shareData: {
        title: "Trots! We hebben een Gouden Luca van Exact ontvangen",
        text: "#exact #goudenluca",
        url: window.location.href,
      },
      renderId: '' as string | LocationQueryValue[],
      videoId: '' as string,
      format: require('@/assets/images/others/videoformat.jpg'),
      thumbnail: require('@/assets/images/others/thumbnail.jpg'),
      video: require('@/assets/videos/video.mp4'),
      companyName: '',
      language: "",
    }
  },
  created() {
    if (this.$route.query.id) {
      this.renderId = this.$route.query.id;
      this.getVideo();
    }
    if (this.$route.query.language) {
      this.language = this.$route.query.language as string;
      this.setLocale();
    }
  },
  mounted() {
    this.onStart()
  },
  computed: {
    tutorialhref() {
      const currentLang = this.$i18n.locale;
      return require(`@/assets/images/others/tutorial-${currentLang}.png`);
    }
  },
  methods: {
    async getVideo() {
      try {
        const res = await axios.get(`${process.env.VUE_APP_API_URL}/api/video/${this.renderId}`);
        if (res.data.data.videoId) this.videoId = res.data.data.videoId;
        if (res.data.data.videoUrl) this.video = res.data.data.videoUrl;
        if (res.data.data.thumbnailUrl) this.thumbnail = res.data.data.thumbnailUrl;
        return res.data.data;
      } catch (err) {
        console.log("Request video Error:", err);
      }
    },
    setLocale() {
      if (this.language === 'NL') {
        this.$i18n.locale = 'nl';
      } else if (this.language === 'BE') {
        this.$i18n.locale = 'nlbe';
      } else if (this.language === 'BENL') {
        this.$i18n.locale = 'nlbe';
      } else if (this.language === 'BEFR') {
        this.$i18n.locale = 'fr';
      }
    },
    onStart() {
      this.boxHeight = document.getElementById("video-box")?.offsetHeight as number;
      if (window.innerWidth >= 576) this.boxHeight += 40;
      else this.boxHeight += 16;
      const video: any = document.getElementById("video");
      video.addEventListener('click', (e: any) => {
        e.preventDefault();
        if (video.paused) video.play();
        else video.pause();
      })
    },
    async handleShare(channel: string) {
      switch (channel) {
        case 'general':
          try {
            await navigator.share(this.shareData);
          } catch (err) {
            console.log(err);
          }
          break;
        case 'twitter':
          const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(`${this.shareData.text}`)}&url=${encodeURIComponent(`${this.shareData.url}`)}`;
          window.open(url, 'twitter', `width=550,height=450,scrollbars=yes,toolbar=no,location=yes`);
          break;
        case 'facebook':
          const fb = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.shareData.url)}`;
          window.open(fb, 'facebook', `width=550, height=450, scrollbars=yes, toolbar=no, location=yes`);
          break;
        case 'whatsapp':
          const waUrl = `https://wa.me/?text=${encodeURIComponent(`${this.shareData.text} ${this.shareData.url}`)}`;
          window.open(waUrl);
          break;
        case 'linkedin':
          const liUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(this.shareData.url)}&title=${encodeURIComponent(this.shareData.title)}&summary=${encodeURIComponent(this.shareData.text)}`;
          window.open(liUrl, 'linkedin', `width=550, height=450, scrollbars=yes, toolbar=no, location=yes`)
          break;
        case 'download':
          let link = document.createElement("a");
          link.href = this.video;
          link.download = "Personal Video.mp4";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          break;
      }
    },
    async delayTime(time: number) {
      return new Promise(function (resolve) {
        setTimeout(resolve, time * 1000);
      });
    },
  }
});
</script>

<style lang="scss" scoped>
.page.home {
  background-color: #FFFFFF;
  overflow: hidden;
  width: 100%;
  height: 100%;

  .background-container {
    position: absolute;
    background-image: url('~@/assets/images/others/background.png');
    background-size: cover;
    background-attachment: local;
    background-repeat: no-repeat;
    overflow: hidden;
    height: 40%;
    min-height: 670px;
    width: 100%;

    @include mobile-down {
      background-image: url('~@/assets/images/others/background-mobile.png');
      background-size: contain;
    }
  }


  .full-width {
    height: 100%;


    section.topbar {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 50px;

      @include tablet-up {
        height: 90px;
      }

      .logo {
        width: 150px;
        margin: 60px;

        @include mobile-down {
          width: 80px;
          margin: 0 15px;
        }
      }
    }

    .upper-title {
      text-align: center;
      margin-top: 264px;

      @include mobile-down {
        margin-bottom: 16px;
        margin-top: 16px;
        font-size: 24px;
        line-height: 32px;
        text-align: left;
      }

      @include tablet-up {
        margin-bottom: 16px;
      }
    }

    section.video {
      user-select: none;
      max-width: 744px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 16px;

      .video-box {
        margin-bottom: 20px;
        position: relative;

        @include tablet-down {
          margin-bottom: 16px;
        }

        .format {
          width: 100%;
          height: auto;
        }

        .video {
          z-index: 1;
          border-radius: 8px;
          position: absolute;
          width: 100%;
        }
      }

      .description {
        font-family: Apax, serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        margin-bottom: 24px;

        p {
          margin-bottom: 16px;
        }

        div {
          a {
            color: #E1141D;
            text-decoration: underline;
          }
        }
      }

      .divider {
        .line {
          position: relative;
          width: 100%;
          height: 1px;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000000B8;
            opacity: 0.24;
          }

          @include tablet-down {
            display: none;
          }
        }
      }

      .share {
        margin-top: 16px;

        @include tablet-up {
          margin-top: 20px;
        }

        .share-title {
          //styleName: Body/Medium
          font-size: 16px;
          text-align: center;
          margin-bottom: 16px;

          @include mobile-down {
            display: none;
          }
        }

        .desktop-share {
          display: none;
          grid-template-columns: repeat(4, 1fr);
          gap: 8px;

          @include tablet-up {
            display: grid;
          }
        }


        .mobile-holder {
          display: flex;
          width: 100%;
          justify-content: center;
        }

        .download-holder {
          display: flex;
          width: 100%;
          justify-content: center;
        }

        .button {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          padding: 6px 16px;
          width: 100%;
          height: 100%;
          color: var(--white);
          border-radius: 4px;
          background-repeat: no-repeat;
          cursor: pointer;
          max-height: 40px;
          max-width: 218px;

          @include tablet-down {
            max-height: 62px;
            max-width: 336px;
            width: 100%;
            height: 100%;
            padding: 12px 16px;
          }

          &.general {
            background-color: #0650D0;
            width: 100%;

            span {
              padding-left: 20px;
              background-image: url("~@/assets/images/icons/icon-share.svg");
              background-repeat: no-repeat;
              background-position: left center;
              background-size: auto 15px;
              font-weight: bold;
            }

            @include tablet-up {
              display: none;
            }
          }

          &.twitter {
            background-color: #1DA1F2;

            .icon {
              width: 24px;
            }
          }

          &.linkedin {
            background-color: #0A66C2;

            .icon {
              width: 18px;
            }
          }

          &.facebook {
            background-color: #1877F2;

            .icon {
              width: 16px;
            }
          }

          &.whatsapp {
            background-color: #25D366;

            .icon {
              width: 28px;
            }
          }

          &.download {
            background-color: transparent;
            color: #0650D0;
            border: 1px solid #0650D0;

            .icon {
              width: 22px;
            }
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .point-tutorial {
      user-select: none;
      max-width: 744px;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 48px;

      img {
        max-width: 100%;
      }
    }
  }
}</style>
